import * as React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Alignwide, MyContainer, MyGrid } from "../components/styles"
import { PostHead } from "../components/postHead"
import { Pagination } from "../components/pagination"
import { OtherImage } from "../components/otherImage"
import { Sidebar } from "../components/sidebar"
import { GatsbyImage } from "gatsby-plugin-image"

const StyledCategoryListWrapper = styled.main`
  ${MyContainer}

  > div {
    ${Alignwide}
    ${MyGrid}
		row-gap: 30px;
    padding: 30px 0;
  }
`

const StyledTitle = styled.div`
  font-size: var(--f2);
  font-weight: normal;
  margin: 0;

  @media (min-width: 768px) {
    font-size: var(--f1);
  }
`

const StyledCategoryList = styled.div`
  display: grid;
  row-gap: 30px;

  @media (min-width: 768px) {
    align-content: start;
    grid-column: 1 / span 8 !important;
  }
`

const StyledArticleLink = styled.article`
  display: grid;
  row-gap: 20px;
  background-color: var(--white);
  padding: 30px 0;

  p {
    margin: 0;
    padding: 0 10px;
    font-size: var(--f5);
  }

  @media (min-width: 768px) {
    p {
      font-size: var(--f4);
      padding: 0 40px;
    }
  }
`

const StyledFigureLink = styled(Link)`
  &:hover {
    opacity: 1;
  }
`

const StyledReadMore = styled(Link)`
  justify-self: center;
  border: solid 1px currentColor;
  padding: 15px 30px;
`

const CategoryList = ({ data, pageContext, location }) => {
  return (
    <Layout>
      <Seo
        pagetitle={`「${pageContext.catName}」に関する記事`}
        pagedesc={`「${pageContext.catName}」に関する記事一覧です`}
        pagepath={location.pathname}
      />
      <StyledCategoryListWrapper>
        <div>
          <StyledCategoryList>
            <StyledTitle>{`「${pageContext.catName}」に関する記事`}</StyledTitle>

            {data.allWpPost.edges.map(({ node }) => {
              return (
                <StyledArticleLink key={node.id}>
                  <PostHead
                    date={node.date}
                    dateJP={node.dateJP}
                    modified={node.modified}
                    modifiedJP={node.modifiedJP}
                    slug={`/blog/${node.slug}/`}
                    title={node.title}
                    categories={node.categories.nodes}
                  />
                  {node.featuredImage ? (
                    <StyledFigureLink to={`/blog/${node.slug}/`}>
                      <figure>
                        <GatsbyImage
                          image={
                            node.featuredImage.node.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={node.title}
                          style={{ height: "100%" }}
                        />
                      </figure>
                    </StyledFigureLink>
                  ) : (
                    <StyledFigureLink to={`/blog/${node.slug}/`}>
                      <OtherImage />
                    </StyledFigureLink>
                  )}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: node.excerpt,
                    }}
                  />

                  <StyledReadMore to={`/blog/${node.slug}/`}>
                    記事を読む
                  </StyledReadMore>
                </StyledArticleLink>
              )
            })}

            <Pagination
              pages={pageContext.pages}
              first={pageContext.isFirst}
              current={pageContext.currentPage}
              preSlug={`category/${pageContext.catSlug}`}
              last={pageContext.isLast}
            />
          </StyledCategoryList>

          <Sidebar />
        </div>
      </StyledCategoryListWrapper>
    </Layout>
  )
}

export default CategoryList

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $catId: String!) {
    allWpPost(
      sort: { order: DESC, fields: date }
      skip: $skip
      limit: $limit
      filter: { categories: { nodes: { elemMatch: { id: { eq: $catId } } } } }
    ) {
      edges {
        node {
          id
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              sourceUrl
              title
              mediaDetails {
                width
                height
              }
            }
          }
          date
          dateJP: date(formatString: "YYYY/MM/DD")
          modified
          modifiedJP: modified(formatString: "YYYY/MM/DD")
          title
          excerpt
          categories {
            nodes {
              id
              slug
              name
            }
          }
        }
      }
    }
  }
`
